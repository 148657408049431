var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"intro-y tab-content mt-5"},[_c('div',{staticClass:"tab-content__pane active"},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[_c('div',{staticClass:"intro-y box col-span-12"},[_c('div',{staticClass:"px-1"},[(_vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList.slice(
                      this.assetsStartIndex,
                      this.assetsEndIndex
                    )),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in",on:{"click":function($event){return _vm.onShowModal(index)}}},[_c('td',[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.tag == "tag_out" ? "Tagged Out" : "Tagged In"))])]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"font-medium whitespace-no-wrap text-white text-xs rounded px-2 py-1",class:{
                          'bg-theme-6': asset.tag == 'tag_out',
                          'bg-theme-9': asset.tag == 'tag_in',
                        }},[_vm._v(_vm._s(_vm.moment(asset.time).format("DD-MM-YYYY hh:mm a")))])]),_vm._m(1,true)])}),0)])]):_vm._e(),(_vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onPrevPagination}},[_c('ChevronLeftIcon',{staticClass:"w-4 h-4",class:{
                        'text-gray-700 cursor-not-allowed': _vm.skip == 0,
                      }})],1)]),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.skip + 1)+"-"+_vm._s(_vm.skip + _vm.take))])]),_vm._m(2),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.count))])]),_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onNextPagination}},[_c('ChevronRightIcon',{staticClass:"w-4 h-4",class:{
                        'text-gray-700 cursor-not-allowed':
                          _vm.skip + _vm.take >= _vm.count,
                      }})],1)])])]):_vm._e()])])])])]),_c('TagInOutModal',{attrs:{"showModal":_vm.showModal,"onHideModal":_vm.onHideModal,"basicDetailData":_vm.assetsList[_vm.selectedDataIndex]}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("EVENT")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TIME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ACTION")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"text-theme-11"},[_vm._v("Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pagination__link"},[_vm._v("of")])])}]

export { render, staticRenderFns }