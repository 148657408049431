












































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CircleIcon,
  TagIcon,
  AwardIcon,
  StarIcon,
  MapPinIcon,
  MapIcon,
  ListIcon,
  UserIcon,
  BarChart2Icon,
} from "vue-feather-icons";

@Component({
  components: {
    CircleIcon,
    TagIcon,
    AwardIcon,
    StarIcon,
    MapPinIcon,
    MapIcon,
    ListIcon,
    UserIcon,
    BarChart2Icon,
  },
})
export default class TagInOutPreview extends Vue {
  @Prop() basicDetailData!: {};
  @Prop() onChangeTab!: Function;

  activeTab = 0;
  tabs = ["History"];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }
}
