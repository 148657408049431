
















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ExternalLinkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "vue-feather-icons";
import TagInOutModal from "./Tag-In-Out-Modal.vue";

@Component({
  components: {
    ExternalLinkIcon,
    TagInOutModal,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
})
export default class TagInOutHistory extends Vue {
  @Prop() basicDetailData!: any;

  assetsList: any = [];

  showModal = false;
  onHideModal() {
    this.showModal = false;
  }

  selectedDataIndex = 0;
  onShowModal(index) {
    this.selectedDataIndex = index;
    setTimeout(() => {
      this.showModal = true;
    }, 100);
  }

  mounted() {
    if (this.basicDetailData.count) {
      this.count = this.basicDetailData.count;
      if (this.count < this.take) {
        this.take = this.count;
      }
      this.assetsList = this.basicDetailData.tagHistory;
    }
  }

  skip = 0;
  take = 50;
  count = 0;

  async fetchAssetWithId() {
    const id = this.basicDetailData.asset.id;
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/tag-in-out/${id}?skip=${this.skip}&take=${this.take}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        if (data.assets) {
          this.assetsList = data.assets;
        }
        if (data.count) {
          this.count = data.count;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  onNextPagination() {
    if (this.skip + this.take >= this.count) return;

    this.skip += 50;
    this.fetchAssetWithId();
  }

  onPrevPagination() {
    if (this.skip <= 0) return;

    this.skip -= 50;
    this.fetchAssetWithId();
  }
}
