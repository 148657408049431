



















































import { Component, Vue } from "vue-property-decorator";
import { ArrowLeftIcon, RefreshCcwIcon, DownloadIcon } from "vue-feather-icons";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
import TagInOutPreview from "../components/tag-in-out-detail/Tag-In-Out-Preview.vue";
import TagInOutHistory from "../components/tag-in-out-detail/Tag-In-Out-History.vue";

import XLSX from "xlsx";
import moment from "moment";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    Loader,
    NoData,
    TagInOutPreview,
    TagInOutHistory,
    DownloadIcon,
  },
})
export default class TagInOutDetail extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  isLoading = true;
  showNoData = false;
  noDataType = "Error";
  basicDetailData: any = "";
  selectedTab = 0;

  mounted() {
    this.fetchAssetWithId();
  }

  onGenerateReport() {
    if (this.basicDetailData.tagHistory.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.basicDetailData.tagHistory.map((x: any) => ({
          asset:
            this.basicDetailData.asset.brand +
            "_" +
            this.basicDetailData.asset.model,
          tag: x.tag == "tag_in" ? "Tagged In" : "Tagged Out",
          time: moment(x.time).format("DD-MM-YYYY hh:mm a"),
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(
        wb,

        this.basicDetailData.asset.brand +
          "_" +
          this.basicDetailData.asset.model +
          "_tag_in_out_" +
          ".xlsx"
      );
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No Tag In/Out found",
        type: "Danger",
      });
    }
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchAssetWithId();
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
  }

  async fetchAssetWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/tag-in-out/${id}?skip=0&take=50`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }
}
